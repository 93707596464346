/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";
import toast from "react-hot-toast";
import Header from "./HeaderOther";
import Footer from "./Footer";
import ItemService from "../services/commonService";

const ContactUS = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  const sendFeedback = async () => {
    let feedback = {
      email,
      content,
      timezone
    };

    if (isValidEmail(email)) {
      // console.log("Valid email address");
    } else {
      toast.error("Invalid email address", {
        duration: 3000,
        position: "top-right",
      });
      return
    }

    setDisabled(true);
    await ItemService.sendFeedback(feedback).then((item) => {
      // console.log(">> item!", item);
      if (item?.status === 200) {
        toast.success("Thanks for Contacting.", {
          duration: 3000,
          position: "top-right",
        });
        setEmail("");
        setContent("");
        setDisabled(false);
      } else {
        toast.error("Please try again !!", {
          duration: 3000,
          position: "top-right",
        });
      }
    });
  };

  function isValidEmail(email) {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  return (
    <main className="main" id="main">
      <header className="hero-section-other">
        <Header />
      </header>

      <section
        className="how-it-works section-spacing"
        style={{ backgroundColor: "#fff", marginTop: "4%" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="titlefont">
                Tell us about your
                <span className="title2"> business</span>
              </h1>
              <h3
                style={{ fontSize: "16px", textAlign: "left" }}
                className="titlefont"
              >
                Thank you for considering reaching out to us! Whether you have
                questions feedback, or inquiries, we're here to assist you.
              </h3>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="exampleBox">How can we help you</label>
                  <textarea
                    className="form-control"
                    id="exampleBox"
                    placeholder=""
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                  ></textarea>
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={sendFeedback}
                  disabled={disabled}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default ContactUS;
