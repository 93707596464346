import React from 'react'   
import { Routes, Route } from 'react-router-dom';

// import Layout  from './Layout/Layout';
import Home from './Pages/Home';
import About from './Pages/About_US';
import FoodBeverage from './Pages/FoodBeverage';
import StadiumsEvents from './Pages/StadiumsEvents';
import Warehousing from './Pages/Warehousing';
import Businesses from './Pages/Businesses';
import ContactUS from './Pages/ContactUS';


function RouterNav() {
    
    return(
        <>  
            {/* <Home/> */}
            <Routes>                                       
                {/* Public route */}
                {/* <Route exact path='*' element={<Home />}></Route> */}
                <Route exact path='/' element={<Home />}></Route>
                <Route exact path='/about-us' element={<About />}></Route>
                
                <Route exact path='/food-beverage' element={<FoodBeverage />}></Route>
                <Route exact path='/stadiums-events' element={<StadiumsEvents />}></Route>
                <Route exact path='/warehousing' element={<Warehousing />}></Route>
                <Route exact path='/for-businesses' element={<Businesses />}></Route>
                <Route exact path='/contact-us' element={<ContactUS />}></Route>

            </Routes>  
            {/* <Footer/> */}
        </>
    );
}


export default RouterNav;