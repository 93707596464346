/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";

export class FAQ extends Component {
  render() {
    return (
      <main className="faq" id="faq">
        <section
          className="section-spacing"
          style={{ backgroundColor: "#fff" }}
        >
          <header className="section-header text-center">
            <h2 className="titlefont">FAQ</h2>
          </header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div class="">
                  <div class="accordion" id="accordionExample">

                    {/* #01 */}
                    <div class="card">
                      <div class="card-head" id="headingTwo">
                        <h2
                          class="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-controls="collapseOne"
                        >
                          Welcome to Workershive
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <h5>How do I book a shift?</h5> */}
                          <p>
                          Hello and welcome to Workershive! We're thrilled to have you join us. Workershive simplifies shift bookings, allowing you to easily fill the shifts you require, anytime and anywhere you need them. With Workershive, managing your staff during shifts becomes effortlessly smooth. After shifts conclude, we advise promptly addressing a few tasks to ensure timely invoicing and swift transition to your next priorities!"
                          </p>

                        </div>
                      </div>
                    </div>

                    {/* #1 */}
                    <div class="card">
                      <div class="card-head" id="headingTwo">
                        <h2
                          class="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-controls="collapseTwo"
                        >
                          How to schedule a shift
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <h5>How do I book a shift?</h5>
                          <p>
                            Partners have the convenience of effortlessly
                            scheduling shifts directly from the company
                            dashboard.
                          </p>

                          <p>Jump to the section you're looking for:</p>

                          <ul>
                            <li>Location Details</li>
                            <li>Shift Type Selection</li>
                            <li>Shift Specifics</li>
                            <li>Position Requirements</li>
                            <li>Staff Selection</li>
                            <li>Attire and Grooming Guidelines</li>
                            <li>Instructions for the Shift</li>
                            <li>Review Pricing Information</li>
                            <li>Confirm and Publish.</li>
                          </ul>

                          <h5>Add Attire and Grooming Details:</h5>
                          <p>
                            {" "}
                            Next, we'll outline the expectations for the
                            professional's appearance upon their arrival at the
                            site.
                          </p>

                          <ul>
                            <li>
                              Attire and Grooming - Select your desired
                              attire requirements using the presets located at
                              the top of the module. If custom attire is needed,
                              please choose the appropriate top wear, bottom
                              wear, footwear, and accessories from the dropdown
                              menus provided.
                            </li>
                            <li>
                              Items Provided Onsite - List any items that
                              will be supplied on-site for the professional
                              during their shift.
                            </li>
                            <li>
                              Restrictions - Select any attire or grooming
                              features that are not accepted on-site from the
                              restriction drop-down menus.
                            </li>
                          </ul>
                          <h5> Review Pricing</h5>
                          <p>
                            The hourly rate for the selected positions will be
                            displayed at the top of the screen. Flexible hourly
                            rates and fixed rate suggestions are determined
                            according to location, position, date, and booking
                            duration.
                          </p>
                          
                          <h5>
                            Instructions for monitoring the arrival of your
                            professionals to a gig.
                          </h5>
                          <p>
                            Recognizing the importance of knowing professionals'
                            arrival times at your gig, you can utilize your
                            company dashboard to contact professionals in case
                            of tardiness. Additionally, professionals are
                            encouraged to communicate with the shift manager if
                            they anticipate arriving later than scheduled.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* #2 */}
                    <div class="card">
                      <div class="card-head" id="headingThree">
                        <h2
                          class="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-controls="collapseThree"
                        >
                          Billing
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <h5>
                            What steps should I follow to include a billing
                            contact on my account?
                          </h5>
                          <p>
                            The team member who posts a shift will automatically
                            receive a summary and receipt at the end of the
                            shift. We acknowledge that certain businesses
                            maintain distinct billing contacts responsible for
                            approving Workershive invoices. The method for
                            adding new billing contacts depends on your billing
                            configuration.
                          </p>

                          <h5>Billing Method</h5>
                          <p>
                            Should you have opted for immediate billing
                            following the shift (also referred to as instant
                            billing), our team is available to assist in
                            modifying the billing contacts associated with your
                            account. Please reach out to your assigned account
                            manager or contact our Support Team to initiate this
                            adjustment.
                          </p>
                          <h5>
                            Can my billing contact make invoice adjustments or
                            view past invoices?
                          </h5>
                          <p>
                            To ensure someone can make adjustments or view past
                            invoices on the dashboard, it's necessary to add
                            them to your workershive account as a team member.
                          </p>

                          <h5>
                            When are invoices sent to the billing contact?
                          </h5>
                          <p>
                            Draft invoices for shifts occurring the previous
                            week are dispatched to billing contacts every
                            Monday, while final invoices are sent on Tuesday.
                          </p>
                          <h5>Can I receive my invoices on a different day?</h5>
                          <p>
                            Regrettably, modifying the invoice dispatch schedule
                            is currently not feasible. Invoices are sent out to
                            all partners on Tuesdays, and this procedure cannot
                            be adjusted at the moment.
                          </p>

                          <h5>Workershive Business Pricing</h5>

                          <p>
                            If a default rate is set up for your account, it
                            will be visible on your booking form. Throughout the
                            booking process, WorkersHive enables you to choose
                            your hourly bill rate. While you have control over
                            the payment, we'll establish a minimum rate and
                            offer in-market insights and suggestions to help you
                            attract top professionals and reach your target fill
                            rate.
                          </p>

                          <h5>
                            Why are there paid backup professionals on my shift?
                          </h5>
                          <p>
                            While we prioritize the reliability of our
                            professionals, there's always a chance they may be
                            unable to attend a shift due to unforeseen
                            circumstances. Hence, we proactively schedule backup
                            professionals in advance to step in if necessary.
                          </p>
                          <h5>
                            Am I getting charged for the paid backup
                            Professionals?
                          </h5>
                          <p>
                            You will incur charges for the paid backup
                            professionals only if you choose to utilize their
                            services for the shift. If you opt to send the paid
                            backup professionals home, you will not be billed,
                            and we will handle the expenses incurred for their
                            assignment. Simply clock them in and out immediately
                            to confirm their attendance for the shift.
                          </p>
                          <h5>
                            What happens if I decide to keep the paid backup
                            Professionals?
                          </h5>
                          <p>
                            Should you choose to retain the paid backup
                            professionals, provide them with the clock-in code
                            at the beginning of their shift, and share the
                            clock-out code upon its conclusion. Charges will
                            apply for the duration of the backup professionals'
                            work, at the same rate as the regular shift.
                          </p>
                          <h5>
                            What happens if I decide to send the paid backup
                            Professionals home?
                          </h5>
                          <p>
                            Should you opt to dismiss the paid backup
                            professionals, you will not incur charges, and we
                            will assume responsibility for the expenses
                            associated with their assignment. Workerhive backup
                            professionals understand they may be sent home if
                            the shift is fully staffed, and they will receive
                            compensation for their time.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* #3 */}
                    <div class="card">
                      <div class="card-head" id="headingThree">
                        <h2
                          class="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-controls="collapseFour"
                        >
                          Helpful tips for WorkersHive partners
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <h5>
                            Does WorkersHive require a minimum number of hours for
                            each gig?
                          </h5>
                          <p>
                            Workershive does not mandate a minimum duration for
                            each gig. While we advise scheduling gigs for a
                            minimum of 4 hours, longer gigs are often
                            prioritized by Workershive professionals for
                            immediate booking, particularly in areas with
                            limited access to public transportation.
                          </p>
                          <h5>What if my professionals arrive late to work?</h5>
                          <p>
                            If a professional arrives late for your shift, you
                            will not incur charges for the time they worked.
                            Your billing statement is automatically updated to
                            reflect the actual start time of the shift compared
                            to the scheduled start time. Charges are only
                            applied when professionals clock in and commence
                            work at your location. If there's a possibility of a
                            professional being late for a shift, a member of our
                            team or the professional will inform the shift
                            manager.
                          </p>
                          <h5>
                            Can I send my WorkersHive Professional home early?
                          </h5>
                          <p>
                            To ensure consideration for the schedules of
                            WorkersHive professionals, please aim to schedule
                            shift times as accurately as possible when initially
                            booking your gig.
                          </p>
                          <h5>Can I ask the Professional to stay later?</h5>
                          <p>
                            If necessary, you have the option to request a
                            professional to extend their shift. If they are able
                            to accommodate and stay beyond their scheduled
                            shift, they will be compensated until they clock
                            out. The professionals will continue to receive
                            payment at the same hourly rate. Our system will
                            automatically update the billing to reflect the time
                            the professional clocks out, eliminating the need
                            for you to contact our Support Team or your Account
                            Manager.
                          </p>

                          <h5>Partners and Tipping</h5>
                          <h5>Patron Tips</h5>
                          <p>
                            According to the federal Fair Labor Standards Act,
                            along with state and local laws, any tips from
                            patrons or customers intended for a Professional
                            belong solely to that Professional (subject to
                            relevant tip pooling regulations). Therefore, if a
                            patron leaves a tip (either in cash or via credit
                            card), Partners are required to transfer those tips
                            to the Professional.
                          </p>
                          <h5>Partner Tips</h5>
                          <p>
                            Partners are not obligated to tip professionals, but
                            they are also not restricted from doing so. If a
                            partner wishes to provide a tip to a professional
                            (for instance, in recognition of exceptional
                            performance), they may do so through the cash
                            option.
                          </p>
                          <h5>How to issue tips to a professional</h5>
                          <p>
                            Following the conclusion of a shift, you have the
                            option to tip an individual professional or
                            distribute a tip among all professionals working
                            during that shift.
                          </p>

                        </div>
                      </div>
                    </div>

                    {/* #4 */}
                    <div class="card">
                      <div class="card-head" id="headingThree">
                        <h2
                          class="mb-0 collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-controls="collapseFive"
                        >
                          Where does Workershive source professionals from?
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          <p>
                          Each Workershive Professional undergoes a comprehensive vetting and activation procedure. This process entails verifying their references, validating their work history, and assessing their skills. This meticulous approach guarantees reliability, top-notch quality, and professionalism in our workforce.
                          </p>
                         

                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default FAQ;
