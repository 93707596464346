/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import FAQ from "./FAQ";

export class Home extends Component {
  render() {
    return (
      <main className="main" id="main">
        <header className="hero-section">
          <video
            id="video-background"
            className="background-video"
            autoPlay
            loop
            muted
            playsInline
            controls
          >
            <source
              src="../assets/img/header_bg/header_bg.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <Header />

          <section className="container text-center welcome-message">
            <div className="row">
              <div className="col-md-12">
                <h1 className="titlefont">
                  Tap into a vast{" "}
                  <span className="title2">network of workers</span> in your
                  vicinity
                </h1>
                <h2 style={{ fontSize: "19px", fontFamily: "ui-rounded" }}>
                  Discover a seamless way to connect with{" "}
                  <span className="title2">skilled professionals</span>
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 col-md-6 sub-form center-block">
                {/* <form id="mc-form">
                                    <div id="mc-error"><i className="fa fa-exclamation-triangle"></i> Please enter a value</div>
                                    <div id="mc-success"><i className="fa fa-envelope"></i> Awesome! We have sent you a confirmation email</div>
                                    <div className="input-group"> <i className="fa fa-envelope"></i>
                                        <input type="email" className="form-control input-lg" placeholder="Email Address" required id="mc-email" />
                                        <span className="input-group-btn">
                                            <button type="submit" className="btn">GET STARTED</button>
                                        </span></div>
                                </form>
                                <p className="form-terms">By signing up you agree to our <a href="" data-toggle="modal" data-target="#modal-terms">terms</a></p> */}


                {/* <span className="input-group-btn">
                  <a
                    href="https://app.workershive.agency/register"
                    className="btn roundBtn"
                  >
                    GET STARTED
                  </a>
                </span> */}
              </div>
            </div>
          </section>
        </header>

        {/* <div
          className="video-intro"
          style={{ paddingBottom: "100px", marginTop: "-50px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-md-push-6">
                <aside className="text-center">
                  <video
                    autoPlay
                    loop
                    muted
                    style={{ height: "250px", width: "100%" }}
                  >
                    <source
                      src="../assets/new_img/section_2.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </aside>
              </div>
              <div className="col-md-5 col-md-offset-2 col-md-pull-6">
                <article>
                  <h2 className="titlefont">
                    Facing <span className="title2">difficulties</span> in
                    hiring skilled and dependable
                    <span className="title2"> workers</span>?
                  </h2>
                  <p style={{ textAlign: "left" }}>
                    Finding the perfect hourly workers for your business can be
                    challenging through traditional staffing agencies. With
                    WorkersHive's platform, it's effortless to discover the
                    qualified and reliable workers you require.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div> */}

        <section
          className="features text-center section-spacing"
          id="features"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row-margin">
                  <header className="section-header">
                    <h2 className="titlefont">
                      Discover a diverse network of{" "}
                      <span className="title2"> local workers </span> in various
                      fields.
                      <span className="title2"> Explore Now!</span>
                    </h2>

                    {/* <h3 className="titlefont">Everything you need</h3> */}
                  </header>
                  <div className="row">
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/key_feature/dish.png"
                        alt="feature-1"
                      />
                      <h4>Food & Beverage</h4>
                      <a href="/food-beverage">Learn More</a>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/key_feature/stadium.png"
                        alt="feature-2"
                      />
                      <h4>Stadiums, Conventions, Events</h4>
                      <a href="/stadiums-events">Learn More</a>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/key_feature/warehouse.png"
                        alt="feature-3"
                      />
                      <h4>Warehousing & Manufacturing</h4>
                      <a href="/warehousing">Learn More</a>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-4.svg" alt="feature-4" />
                      <h4>Google Maps</h4>
                      <p>
                        Nullam pellentesque nisl nec tincidunt viverra hendrerit
                        felis mi.{" "}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-5.svg" alt="feature-5" />
                      <h4>Twitter Feed</h4>
                      <p>
                        Fusce finibus condimentum purus. Sed sagittis erat sed
                        posuere cursus.{" "}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-6.svg" alt="feature-6" />
                      <h4>Font Awesome</h4>
                      <p>
                        Proin convallis sodales volutpat. Suspene varius mollis
                        risus arcu.{" "}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="user-reviews section-spacing text-center" id="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-md-9 center-block">
                <div className="reviews-slider owl-carousel owl-theme">
                  <div className="item">
                    <blockquote>
                      <figure>
                        {" "}
                        <img
                          src="assets/new_img/test1.png"
                          alt="user"
                          className="img-circle"
                        />
                      </figure>
                      <p>
                        “We've chosen WorkersHive as our primary staffing
                        partner after past experiences with several other
                        contractor companies. Their exceptional service aligns
                        perfectly with our requirements. The team consistently
                        demonstrates professionalism, punctuality, and adherence
                        to our standards, seamlessly integrating with our
                        in-house staff. With a co-founder who previously served
                        as a contractor, WorkersHive truly grasps our
                        expectations, consistently exceeding them.”
                      </p>
                      <footer>
                        {" "}
                        <cite>
                          Gary Tummings,{" "}
                          <i>
                            Corporate Director Venue Operations, Longwood Venues
                          </i>
                        </cite>{" "}
                      </footer>
                    </blockquote>
                  </div>
                  {/* <div className="item">
                    <blockquote>
                      <figure>
                        {" "}
                        <img
                          src="assets/img/user-review-2.jpg"
                          alt="user"
                          className="img-circle"
                        />
                      </figure>
                      <p>
                        “Nullam pellentesque nisl nec velit tincidunt viverra.
                        Ut ut felis mi. Mauris tristique ante non feugiat
                        fermentum morbi nec ultricies est accumsan ligula.”
                      </p>
                      <footer>
                        {" "}
                        <cite>
                          Jessica Campbell, <i>Blogger</i>
                        </cite>{" "}
                      </footer>
                    </blockquote>
                  </div>
                  <div className="item">
                    <blockquote>
                      <figure>
                        {" "}
                        <img
                          src="assets/img/user-review-3.jpg"
                          alt="user"
                          className="img-circle"
                        />
                      </figure>
                      <p>
                        “Donec ullamcorper imperdiet velit, et tincidunt lectus
                        bibendum ut. Fusce ut nunc tortor. Donec vel tempus
                        nisl, sed porta efficitur eu justo leo sollicitudin
                        gravida.”
                      </p>
                      <footer>
                        {" "}
                        <cite>
                          Hannah Romero, <i>Designer at xyz</i>
                        </cite>{" "}
                      </footer>
                    </blockquote>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-spacing BusinessesSection">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <header className="section-header">
                  <h2 className="titlefont">Services we provide</h2>
                  <h3 className="titlefont">
                    From hospitality to warehouse skills, we got you covered. To
                    see the full list in your area,
                    {/* <a href="https://app.workershive.agency/register">
                      {" "}
                      create an account
                    </a> */}
                    .
                  </h3>
                </header>
              </div>

              <div className="col-sm-4 text-center">
                <div className="sectionSpace">
                  <p className="sectionTitle">Event</p>
                  <div className="row">
                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/event/1.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Concessions</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/event/2.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Event server</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/event/3.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Busser</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/event/4.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Bartenders</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 text-center">
                <div className="sectionSpace">
                  <p className="sectionTitle">Food</p>
                  <div className="row">
                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/food/1.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Dishwasher</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/food/2.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Sanitation</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/food/3.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Cook</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/food/4.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Runner</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 text-center">
                <div className="sectionSpace">
                  <p className="sectionTitle">Warehousing</p>
                  <div className="row">
                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/warehosue/1.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>General labor</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/warehosue/2.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Merchandising</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/warehosue/3.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Picker/Packer</h4>
                    </div>

                    <div class="col-sm-6 col-xs-6 d-flex flex-column align-items-center justify-content-center">
                      <img
                        src="assets/new_img/businesses_section/warehosue/4.png"
                        alt="feature-1"
                        className="ImagesBusinesses"
                      />
                      <h4>Warehouse</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-spacing"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <header className="section-header">
                  <h2 className="titlefont">
                    Trusted by{" "}
                    <span className="title2"> small and large businesses</span>
                  </h2>
                </header>

                <div class="image-container-trusted">
                  <img
                    src="assets/new_img/trusted/1.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/2.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/3.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/4.png"
                    alt="contact-us"
                    className="image-trusted"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-spacing"
          style={{ backgroundColor: "#000", color: "#fff" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <header className="section-header">
                  <h2 className="titlefont" style={{ color: "#fff" }}>
                    Staffed through
                    <span className="title2"> WorkersHive</span>
                  </h2>
                </header>

                <div className="gallery">
                  {/* <div className="image-container">
                    <img
                      src="assets/new_img/staffed/1.jpg"
                      alt="Image1"
                      className="image"
                    />
                    <div className="overlay">
                      <p className="text">Raffles Boston</p>
                    </div>
                  </div> */}
                  {/* Image Sections */}

                  <div className="image-container">
                    <img
                      src="assets/new_img/staffed/2.jpg"
                      alt="Image1"
                      className="image"
                    />
                    <div className="overlay">
                      <p className="text">The Tower</p>
                    </div>
                  </div>
                  {/* Image Sections */}

                  <div className="image-container">
                    <img
                      src="assets/new_img/staffed/3.jpg"
                      alt="Image1"
                      className="image"
                    />
                    <div className="overlay">
                      <p className="text">Stateroom</p>
                    </div>
                  </div>
                  {/* Image Sections */}

                  <div className="image-container">
                    <img
                      src="assets/new_img/staffed/4.jpg"
                      alt="Image1"
                      className="image"
                    />
                    <div className="overlay">
                      <p className="text">Alden Castle</p>
                    </div>
                  </div>
                  {/* Image Sections */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <FAQ />

        <Footer />
      </main>
    );
  }
}

export default Home;
