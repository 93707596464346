
// ALL API CONSTANTS LIST

// Development
// const SEND_FEEDBACK_API = 'https://api.workershive.agency/api/sendFeedback';

// Production 
const SEND_FEEDBACK_API = 'https://connect.workershive.agency/api/sendFeedback'; 

// this block needed to get the login Token and Login user _id 
// let localData = localStorage.getItem('userData');
// let userData={}
// if(localData){
//     userData = JSON.parse(localData);
// }

/* Service Function starts here */

// API Error Handling
async function handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
}

// API Error Handling
async function handleError(error) {
    console.log(error.message);
}

// Common GET Method 
async function callGET(api_url) {
    return fetch(api_url,{
                method:'GET',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    // "Authorization": 'Bearer '+ userData.token
                }
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                if(items.responseCode !== 401){
                    // console.log(">> GET Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                }            
            }).catch(error => {
                console.log(">> e", error);
                handleError(error);
            });
}

// Common POST Method
async function callPOST(api_url,param) {
    return await fetch(api_url,{
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json",
                    // "Authorization": 'Bearer '+ userData.token
                },
                body:JSON.stringify(param)
            }).then(response => {
                if (!response.ok) {
                    handleResponseError(response);
                }
                return response.json();
            }).then(items => {
                // console.log(">> Service Login Data",items);
                if(items.responseCode !== 401){
                    // console.log(">> POST Service Call",items);
                    return items;
                }else{
                    console.log(">> Session Timeout",items);
                }     
               
            }).catch(error => {
                handleError(error);
            });
}

//POST - sendFeedback
async function sendFeedback(param) { // This method calling from Redux
    let api_url= SEND_FEEDBACK_API;
    return callPOST(api_url, param);
}



const func = {
    handleResponseError,
    handleError,
    callGET,
    callPOST,

    sendFeedback
};

export default func;