
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import './App.scss';
// import './common.scss'
// import './dashboard.scss'
import "bootstrap-icons/font/bootstrap-icons.css";
import RouterNav from './Router';
// import { Providers } from './redux/provider';


function App() {
  return (
    <>
      {/* <Providers>  */}
        <BrowserRouter>
          <RouterNav/> 
          <Analytics />
        </BrowserRouter>
      {/* </Providers> */}
    </>
  );
}
export default App;
