/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <>
        <footer
          className="site-footer section-spacing"
          style={{ backgroundColor: "#f3f3f3", padding: "36px 40px 18px 40px" }}
        >
          <div className="container">
            <div className="row footer-col-spacing">
              <div className="col-md-3 footer-logo">
                <a href="/">
                  {/* <img src="assets/img/logo/wh_logo.png" alt="logo footer" style={{ width: "160px" }} /></a>  */}
                  <span className="workersHive_2 workers_2">Workers</span>
                  <span className="workersHive_2 hive">Hive</span>
                </a>
              </div>
              <div className="col-sm-4 col-md-2">
                <ul className="footer-nav">
                  
                    <h3>COMPANY</h3>
                  
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/for-businesses">For Businesses</a>
                  </li>
                  <li>
                    <a href="/#testimonials">Testimonials</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 col-md-2">
                <ul className="footer-nav">
                  
                    <h3>Resources</h3>
                
                  <li>
                    <a href="/#faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-4 col-md-4">
                <ul className="footer-nav">
                  
                    <h3>Category</h3>
              
                  <li>
                    <a href="/food-beverage">Food & Beverage</a>
                  </li>
                  <li>
                    <a href="/stadiums-events">Stadiums, Conventions, Events</a>
                  </li>
                  <li>
                    <a href="/warehousing">Warehousing & Manufacturing</a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-sm-4 col-md-3">
                <ul className="footer-nav">
                  <li>
                    <h3>CONTACT US</h3>
                  </li>
                  <li>
                    <a href="mailto:pavan@workershive.agency">
                      pavan@workershive.agency
                    </a>{" "}
                    <br />
                    <a href="mailto:carlos@workershive.agency">
                      carlos@workershive.agency
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="clearfix visible-sm-block"></div>
              {/* <div className="col-md-3">
                <ul className="social">
                  <li>
                    <a href="" className="a-facebook">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="" className="a-twitter">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="" className="a-google-plus">
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <a
            href=""
            className="chat-btn"
            data-toggle="modal"
            data-target="#modal-contact-form"
          >
            &nbsp;{" "}
          </a>{" "}
        </footer>

        <footer className="copyright-terms">
          <div className="container">
            <div className="row">
              <div className="col-sm-5 col-md-6">
                {" "}
                <small>
                  {" "}
                  &copy; 2024 WorkersHive. All rights reserved.
                </small>{" "}
              </div>
              <div className="col-sm-7 col-md-6">
                <ul className="terms-privacy">
                  <li>
                    <a href="" data-toggle="modal" data-target="#TermsOfUse">
                      Terms
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      data-toggle="modal"
                      data-target="#privacy_policy "
                    >
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a href="" data-toggle="modal" data-target="#cookie">
                      Cookies
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        <div
          className="modal fade"
          id="modal-google-map"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <div className="map">
                  <div id="map-canvas"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade site-policy"
          id="modal-terms"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <h2 className="modal-title text-center" id="ModalLabel">
                  Terms of Use 01
                </h2>
              </div>
              <div className="modal-body">
                <p>
                  This privacy policy discloses the privacy practices for
                  (website address). This privacy policy applies solely to
                  information collected by this web site. It will notify you of
                  the following:{" "}
                </p>
                <ul>
                  <li>
                    What personally identifiable information is collected from
                    you through the web site, how it is used and with whom it
                    may be shared.
                  </li>
                  <li>
                    What choices are available to you regarding the use of your
                    data.
                  </li>
                  <li>
                    The security procedures in place to protect the misuse of
                    your information.
                  </li>
                  <li>
                    How you can correct any inaccuracies in the information.
                  </li>
                </ul>
                <h3>Information Collection, Use, and Sharing </h3>
                <p>
                  We are the sole owners of the information collected on this
                  site. We only have access to/collect information that you
                  voluntarily give us via email or other direct contact from
                  you. We will not sell or rent this information to anyone.
                </p>
                <p>
                  We will use your information to respond to you, regarding the
                  reason you contacted us. We will not share your information
                  with any third party outside of our organization, other than
                  as necessary to fulfill your request, e.g. to ship an order.
                </p>
                <p>
                  Unless you ask us not to, we may contact you via email in the
                  future to tell you about specials, new products or services,
                  or changes to this privacy policy.
                </p>
                <h3>Your Access to and Control Over Information </h3>
                <p>
                  You may opt out of any future contacts from us at any time.
                  You can do the following at any time by contacting us via the
                  email address or phone number given on our website:
                </p>
                <ul>
                  <li>See what data we have about you, if any.</li>
                  <li>Change/correct any data we have about you.</li>
                  <li>Have us delete any data we have about you.</li>
                  <li>
                    Express any concern you have about our use of your data.
                  </li>
                </ul>
                <h3>Security </h3>
                <p>
                  We take precautions to protect your information. When you
                  submit sensitive information via the website, your information
                  is protected both online and offline.
                </p>
                <p>
                  Wherever we collect sensitive information (such as credit card
                  data), that information is encrypted and transmitted to us in
                  a secure way. You can verify this by looking for a closed lock
                  icon at the bottom of your web browser, or looking for "https"
                  at the beginning of the address of the web page.
                </p>
                <p>
                  While we use encryption to protect sensitive information
                  transmitted online, we also protect your information offline.
                  Only employees who need the information to perform a specific
                  job (for example, billing or customer service) are granted
                  access to personally identifiable information. The
                  computers/servers in which we store personally identifiable
                  information are kept in a secure environment.
                </p>
                <h3>Registration </h3>
                <p>
                  In order to use this website, a user must first complete the
                  registration form. During registration a user is required to
                  give certain information (such as name and email address).
                  This information is used to contact you about the
                  products/services on our site in which you have expressed
                  interest. At your option, you may also provide demographic
                  information (such as gender or age) about yourself, but it is
                  not required.
                </p>
                <h3>Orders </h3>
                <p>
                  We request information from you on our order form. To buy from
                  us, you must provide contact information (like name and
                  shipping address) and financial information (like credit card
                  number, expiration date). This information is used for billing
                  purposes and to fill your orders. If we have trouble
                  processing an order, we'll use this information to contact
                  you.
                </p>
                <h3>Cookies </h3>
                <p>
                  We use "cookies" on this site. A cookie is a piece of data
                  stored on a site visitor's hard drive to help us improve your
                  access to our site and identify repeat visitors to our site.
                  For instance, when we use a cookie to identify you, you would
                  not have to log in a password more than once, thereby saving
                  time while on our site. Cookies can also enable us to track
                  and target the interests of our users to enhance the
                  experience on our site. Usage of a cookie is in no way linked
                  to any personally identifiable information on our site.
                </p>
                <h3>Updates</h3>
                <p>
                  Our Privacy Policy may change from time to time and all
                  updates will be posted on this page.
                </p>
                <p>
                  If you feel that we are not abiding by this privacy policy,
                  you should contact us immediately via telephone at{" "}
                  <a href="tel:+1856-236-1853">+1856-236-1853</a> or via email{" "}
                  <a href="mailto:Contact@archer.com">Contact@archer.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modal-contact-form"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-body">
                <div className="contact-form text-center">
                  <header className="section-header">
                    {" "}
                    <img src="assets/img/support-icon.svg" alt="support icon" />
                    <h2>Contact us</h2>
                    <h3>Have any questions? Send us a message.</h3>
                  </header>
                  <form
                    className="cta-form cta-light"
                    action="php/contact.php"
                    method="post"
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="contact-name form-control input-lg"
                        placeholder="Name *"
                        id="contact-name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        className="contact-email form-control input-lg"
                        placeholder="Email address *"
                        id="contact-email"
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="contact-message form-control input-lg"
                        rows="4"
                        placeholder="Message *"
                        id="contact-message"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="antispam"
                        placeholder="Antispam question: 7 + 5 = ?"
                        className="contact-antispam form-control input-lg"
                        id="contact-antispam"
                      />
                    </div>
                    <button type="submit" className="btn">
                      SEND MESSAGE
                    </button>
                  </form>
                </div>

                <p className="contact-form-success">
                  <i className="fa fa-check"></i>
                  <span>Thanks for contacting us!</span> We will get back to you
                  very soon.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Cookie Policy */}
        <div
          className="modal fade site-policy"
          id="cookie"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <h2 className="modal-title text-center" id="ModalLabel">
                  Cookie Policy for workershive.agency
                </h2>
              </div>
              <div className="modal-body">
                <p>
                  This is the Cookie Policy for WorkersHive.agency, accessible
                  from https://workershive.agency/
                </p>
                <h3>What Are Cookies</h3>
                <p>
                  As is common practice with almost all professional websites
                  this site uses cookies, which are tiny files that are
                  downloaded to your computer, to improve your experience. This
                  page describes what information they gather, how we use it and
                  why we sometimes need to store these cookies. We will also
                  share how you can prevent these cookies from being stored
                  however this may downgrade or 'break' certain elements of the
                  site's functionality.
                </p>

                <h3>How We Use Cookies </h3>
                <p>
                  We use cookies for a variety of reasons detailed below.
                  Unfortunately in most cases there are no industry standard
                  options for disabling cookies without completely disabling the
                  functionality and features they add to this site. It is
                  recommended that you leave on all cookies if you are not sure
                  whether you need them or not in case they are used to provide
                  a service that you use.{" "}
                </p>

                <h3>Disabling Cookies</h3>
                <p>
                  You can prevent the setting of cookies by adjusting the
                  settings on your browser (see your browser Help for how to do
                  this). Be aware that disabling cookies will affect the
                  functionality of this and many other websites that you visit.
                  Disabling cookies will usually result in also disabling
                  certain functionality and features of this site. Therefore it
                  is recommended that you do not disable cookies.{" "}
                </p>

                <h3>The Cookies We Set </h3>

                <ul>
                  <li>Account related cookies</li>
                  <p>
                    If you create an account with us then we will use cookies
                    for the management of the signup process and general
                    administration. These cookies will usually be deleted when
                    you log out however in some cases they may remain afterwards
                    to remember your site preferences when logged out.{" "}
                  </p>

                  <li>Login related cookies</li>
                  <p>
                    We use cookies when you are logged in so that we can
                    remember this fact. This prevents you from having to log in
                    every single time you visit a new page. These cookies are
                    typically removed or cleared when you log out to ensure that
                    you can only access restricted features and areas when
                    logged in.{" "}
                  </p>

                  <li>Email newsletters related cookies</li>
                  <p>
                    This site offers newsletter or email subscription services
                    and cookies may be used to remember if you are already
                    registered and whether to show certain notifications which
                    might only be valid to subscribed/unsubscribed users.
                  </p>

                  <li>Orders processing related cookies</li>
                  <p>
                    This site offers e-commerce or payment facilities and some
                    cookies are essential to ensure that your order is
                    remembered between pages so that we can process it properly.
                  </p>

                  <li>Surveys related cookies</li>
                  <p>
                    {" "}
                    From time to time we offer user surveys and questionnaires
                    to provide you with interesting insights, helpful tools, or
                    to understand our user base more accurately. These surveys
                    may use cookies to remember who has already taken part in a
                    survey or to provide you with accurate results after you
                    change pages.
                  </p>

                  <li>Forms related cookies</li>
                  <p>
                    {" "}
                    When you submit data through a form such as those found on
                    contact pages or comment forms cookies may be set to
                    remember your user details for future correspondence.
                  </p>

                  <li>Site preferences cookies</li>
                  <p>
                    {" "}
                    In order to provide you with a great experience on this site
                    we provide the functionality to set your preferences for how
                    this site runs when you use it. In order to remember your
                    preferences we need to set cookies so that this information
                    can be called whenever you interact with a page that is
                    affected by your preferences.
                  </p>
                </ul>

                <h3>Third Party Cookies</h3>
                <p>
                  In some special cases we also use cookies provided by trusted
                  third parties. The following section details which third party
                  cookies you might encounter through this site.
                </p>

                <ul>
                  <li>
                    This site uses Google Analytics which is one of the most
                    widespread and trusted analytics solutions on the web for
                    helping us to understand how you use the site and ways that
                    we can improve your experience. These cookies may track
                    things such as how long you spend on the site and the pages
                    that you visit so we can continue to produce engaging
                    content.
                    <br />
                    For more information on Google Analytics cookies, see the
                    official Google Analytics page.
                  </li>

                  <li>
                    Third party analytics are used to track and measure usage of
                    this site so that we can continue to produce engaging
                    content. These cookies may track things such as how long you
                    spend on the site or pages you visit which helps us to
                    understand how we can improve the site for you.
                  </li>

                  <li>
                    From time to time we test new features and make subtle
                    changes to the way that the site is delivered. When we are
                    still testing new features these cookies may be used to
                    ensure that you receive a consistent experience whilst on
                    the site whilst ensuring we understand which optimisations
                    our users appreciate the most.
                  </li>

                  <li>
                    As we sell products it's important for us to understand
                    statistics about how many of the visitors to our site
                    actually make a purchase and as such this is the kind of
                    data that these cookies will track. This is important to you
                    as it means that we can accurately make business predictions
                    that allow us to monitor our advertising and product costs
                    to ensure the best possible price.
                  </li>

                  <li>
                    The Google AdSense service we use to serve advertising uses
                    a DoubleClick cookie to serve more relevant ads across the
                    web and limit the number of times that a given ad is shown
                    to you.
                    <br />
                    For more information on Google AdSense see the official
                    Google AdSense privacy FAQ.
                  </li>

                  <li>
                    We use adverts to offset the costs of running this site and
                    provide funding for further development. The behavioral
                    advertising cookies used by this site are designed to ensure
                    that we provide you with the most relevant adverts where
                    possible by anonymously tracking your interests and
                    presenting similar things that may be of interest.
                  </li>

                  <li>
                    Several partners advertise on our behalf and affiliate
                    tracking cookies simply allow us to see if our customers
                    have come to the site through one of our partner sites so
                    that we can credit them appropriately and where applicable
                    allow our affiliate partners to provide any bonus that they
                    may provide you for making a purchase.
                  </li>

                  <li>
                    We also use social media buttons and/or plugins on this site
                    that allow you to connect with your social network in
                    various ways. For these to work the following social media
                    sites including instagram, will set cookies through our site
                    which may be used to enhance your profile on their site or
                    contribute to the data they hold for various purposes
                    outlined in their respective privacy policies.
                  </li>
                </ul>

                <h3>More Information</h3>
                <p>
                  Hopefully that has clarified things for you and as was
                  previously mentioned if there is something that you aren't
                  sure whether you need or not it's usually safer to leave
                  cookies enabled in case it does interact with one of the
                  features you use on our site.
                </p>
                <p>
                  {" "}
                  For more general information on cookies, please read the
                  Cookies Policy article.
                </p>
                {/* <p>
                  However if you are still looking for more information then you
                  can contact us through one of our preferred contact methods:
                  <br />
                  Email:
                  <a href="mailto:carlos@workersHive.agency">
                    carlos@workersHive.agency
                  </a>
                  ,
                  <a href="mailto: pavan@workershive.agency">
                    {" "}
                    pavan@workershive.agency
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* Terms of Use */}
        <div
          className="modal fade site-policy"
          id="TermsOfUse"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <h2 className="modal-title text-center" id="ModalLabel">
                  WorkersHive Terms of Use
                </h2>
              </div>
              <div className="modal-body">
                <p>Version 1.0</p>

                <p>
                  The workershive.agency website located at
                  https://workershive.agency/ is a copyrighted work belonging to
                  workershive. Certain features of the Site may be subject to
                  additional guidelines, terms, or rules, which will be posted
                  on the Site in connection with such features.
                </p>

                <p>
                  All such additional terms, guidelines, and rules are
                  incorporated by reference into these Terms.
                </p>

                <p>
                  These Terms of Use describe the legally binding terms and
                  conditions that oversee your use of the Site. BY LOGGING INTO
                  THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you
                  represent that you have the authority and capacity to enter
                  into these Terms. YOU SHOULD BE AT LEAST 18 YEARS OF AGE TO
                  ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE PROVISION OF
                  THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
                </p>

                <p>
                  These terms require the use of arbitration Section 10.2 on an
                  individual basis to resolve disputes and also limit the
                  remedies available to you in the event of a dispute.
                </p>

                <h3>Access to the Site </h3>
                <p>
                  <b>Subject to these Terms.</b> Company grants you a
                  non-transferable, non-exclusive, revocable, limited license to
                  access the Site solely for your own personal, noncommercial
                  use.
                </p>
                <p>
                  <b>Certain Restrictions.</b> The rights approved to you in
                  these Terms are subject to the following restrictions: (a) you
                  shall not sell, rent, lease, transfer, assign, distribute,
                  host, or otherwise commercially exploit the Site; (b) you
                  shall not change, make derivative works of, disassemble,
                  reverse compile or reverse engineer any part of the Site; (c)
                  you shall not access the Site in order to build a similar or
                  competitive website; and (d) except as expressly stated
                  herein, no part of the Site may be copied, reproduced,
                  distributed, republished, downloaded, displayed, posted or
                  transmitted in any form or by any means unless otherwise
                  indicated, any future release, update, or other addition to
                  functionality of the Site shall be subject to these Terms. All
                  copyright and other proprietary notices on the Site must be
                  retained on all copies thereof.
                </p>
                <p>
                  Company reserves the right to change, suspend, or cease the
                  Site with or without notice to you. You approved that the
                  Company will not be held liable to you or any third-party for
                  any change, interruption, or termination of the Site or any
                  part.
                </p>
                <p>
                  <b>No Support or Maintenance.</b> You agree that the Company
                  will have no obligation to provide you with any support in
                  connection with the Site.
                </p>

                <p>
                  Excluding any User Content that you may provide, you are aware
                  that all the intellectual property rights, including
                  copyrights, patents, trademarks, and trade secrets, in the
                  Site and its content are owned by Company or Company's
                  suppliers. Note that these Terms and access to the Site do not
                  give you any rights, title or interest in or to any
                  intellectual property rights, except for the limited access
                  rights expressed in Section 2.1. Company and its suppliers
                  reserve all rights not granted in these Terms.
                </p>

                <h3>User Content</h3>
                <p>
                  <b>User Content.</b> "User Content" means any and all
                  information and content that a user submits to the Site. You
                  are exclusively responsible for your User Content. You bear
                  all risks associated with use of your User Content. You hereby
                  certify that your User Content does not violate our Acceptable
                  Use Policy. You may not represent or imply to others that your
                  User Content is in any way provided, sponsored or endorsed by
                  the Company. Because you alone are responsible for your User
                  Content, you may expose yourself to liability. Company is not
                  obliged to backup any User Content that you post; also, your
                  User Content may be deleted at any time without prior notice
                  to you. You are solely responsible for making your own backup
                  copies of your User Content if you desire.
                </p>

                <p>
                  You hereby grant to Company an irreversible, nonexclusive,
                  royalty-free and fully paid, worldwide license to reproduce,
                  distribute, publicly display and perform, prepare derivative
                  works of, incorporate into other works, and otherwise use and
                  exploit your User Content, and to grant sublicenses of the
                  foregoing rights, solely for the purposes of including your
                  User Content in the Site. You hereby irreversibly waive any
                  claims and assertions of moral rights or attribution with
                  respect to your User Content.
                </p>

                <p>
                  <b>Acceptable Use Policy.</b> The following terms constitute
                  our "Acceptable Use Policy": You agree not to use the Site to
                  collect, upload, transmit, display, or distribute any User
                  Content (i) that violates any third-party right or any
                  intellectual property or proprietary right; (ii) that is
                  unlawful, harassing, abusive, tortious, threatening, harmful,
                  invasive of another's privacy, vulgar, defamatory, false,
                  intentionally misleading, trade libelous, pornographic,
                  obscene, patently offensive, promotes racism, bigotry, hatred,
                  or physical harm of any kind against any group or individual;
                  (iii) that is harmful to minors in any way; or (iv) that is in
                  violation of any law, regulation, or obligations or
                  restrictions imposed by any third party.
                </p>

                <p>
                  In addition, you agree not to: (i) upload, transmit, or
                  distribute to or through the Site any software intended to
                  damage or alter a computer system or data; (ii) send through
                  the Site unsolicited or unauthorized advertising, promotional
                  materials, junk mail, spam, chain letters, pyramid schemes, or
                  any other form of duplicative or unsolicited messages; (iii)
                  use the Site to harvest, collect, gather or assemble
                  information or data regarding other users without their
                  consent; (iv) interfere with, disrupt, or create an undue
                  burden on servers or networks connected to the Site, or
                  violate the regulations, policies or procedures of such
                  networks; (v) attempt to gain unauthorized access to the Site,
                  whether through password mining or any other means; (vi)
                  harass or interfere with any other user's use and enjoyment of
                  the Site; or (vi) use software or automated agents or scripts
                  to produce multiple accounts on the Site, or to generate
                  automated searches, requests, or queries to the Site.
                </p>

                <p>
                  We reserve the right to review any User Content, and to
                  investigate and/or take appropriate action against you in our
                  sole discretion if you violate the Acceptable Use Policy or
                  any other provision of these Terms or otherwise create
                  liability for us or any other person. Such action may include
                  removing or modifying your User Content, terminating your
                  Account in accordance with Section 8, and/or reporting you to
                  law enforcement authorities.
                </p>

                <p>
                  If you provide Company with any feedback or suggestions
                  regarding the Site, you hereby assign to Company all rights in
                  such Feedback and agree that Company shall have the right to
                  use and fully exploit such Feedback and related information in
                  any manner it believes appropriate. Company will treat any
                  Feedback you provide to Company as non-confidential and
                  non-proprietary.
                </p>

                <p>
                  You agree to indemnify and hold Company and its officers,
                  employees, and agents harmless, including costs and attorneys'
                  fees, from any claim or demand made by any third-party due to
                  or arising out of (a) your use of the Site, (b) your violation
                  of these Terms, (c) your violation of applicable laws or
                  regulations or (d) your User Content. Company reserves the
                  right to assume the exclusive defense and control of any
                  matter for which you are required to indemnify us, and you
                  agree to cooperate with our defense of these claims. You agree
                  not to settle any matter without the prior written consent of
                  the Company. Company will use reasonable efforts to notify you
                  of any such claim, action or proceeding upon becoming aware of
                  it.
                </p>

                <h3>Third-Party Links & Ads; Other Users</h3>

                <p>
                  <b>Third-Party Links & Ads.</b> The Site may contain links to
                  third-party websites and services, and/or display
                  advertisements for third-parties. Such Third-Party Links & Ads
                  are not under the control of Company, and Company is not
                  responsible for any Third-Party Links & Ads. Company provides
                  access to these Third-Party Links & Ads only as a convenience
                  to you, and does not review, approve, monitor, endorse,
                  warrant, or make any representations with respect to
                  Third-Party Links & Ads. You use all Third-Party Links & Ads
                  at your own risk, and should apply a suitable level of caution
                  and discretion in doing so. When you click on any of the
                  Third-Party Links & Ads, the applicable third party's terms
                  and policies apply, including the third party's privacy and
                  data gathering practices.
                </p>

                <p>
                  <b>Other Users.</b> Each Site user is solely responsible for
                  any and all of its own User Content. Because we do not control
                  User Content, you acknowledge and agree that we are not
                  responsible for any User Content, whether provided by you or
                  by others. You agree that the Company will not be responsible
                  for any loss or damage incurred as the result of any such
                  interactions. If there is a dispute between you and any Site
                  user, we are under no obligation to become involved.
                </p>

                <p>
                  You hereby release and forever discharge the Company and our
                  officers, employees, agents, successors, and assigns from, and
                  hereby waive and relinquish, each and every past, present and
                  future dispute, claim, controversy, demand, right, obligation,
                  liability, action and cause of action of every kind and
                  nature, that has arisen or arises directly or indirectly out
                  of, or that relates directly or indirectly to, the Site. If
                  you are a California resident, you hereby waive California
                  civil code section 1542 in connection with the foregoing,
                  which states: "a general release does not extend to claims
                  which the creditor does not know or suspect to exist in his or
                  her favor at the time of executing the release, which if known
                  by him or her must have materially affected his or her
                  settlement with the debtor."
                </p>

                <p>
                  <b>Cookies and Web Beacons.</b> Like any other website,
                  workershive.agency uses ‘cookies'. These cookies are used to
                  store information including visitors' preferences, and the
                  pages on the website that the visitor accessed or visited. The
                  information is used to optimize the users' experience by
                  customizing our web page content based on visitors' browser
                  type and/or other information.
                </p>

                <h3>Disclaimers</h3>

                <p>
                  The site is provided on an "as-is" and "as available" basis,
                  and company and our suppliers expressly disclaim any and all
                  warranties and conditions of any kind, whether express,
                  implied, or statutory, including all warranties or conditions
                  of merchantability, fitness for a particular purpose, title,
                  quiet enjoyment, accuracy, or non-infringement. We and our
                  suppliers make not guarantee that the site will meet your
                  requirements, will be available on an uninterrupted, timely,
                  secure, or error-free basis, or will be accurate, reliable,
                  free of viruses or other harmful code, complete, legal, or
                  safe. If applicable law requires any warranties with respect
                  to the site, all such warranties are limited in duration to
                  ninety (90) days from the date of first use.
                </p>

                <p>
                  Some jurisdictions do not allow the exclusion of implied
                  warranties, so the above exclusion may not apply to you. Some
                  jurisdictions do not allow limitations on how long an implied
                  warranty lasts, so the above limitation may not apply to you.
                </p>

                <h3>Limitation on Liability</h3>
                <p>
                  To the maximum extent permitted by law, in no event shall
                  company or our suppliers be liable to you or any third-party
                  for any lost profits, lost data, costs of procurement of
                  substitute products, or any indirect, consequential,
                  exemplary, incidental, special or punitive damages arising
                  from or relating to these terms or your use of, or
                  incapability to use the site even if company has been advised
                  of the possibility of such damages. Access to and use of the
                  site is at your own discretion and risk, and you will be
                  solely responsible for any damage to your device or computer
                  system, or loss of data resulting therefrom.
                </p>

                <p>
                  To the maximum extent permitted by law, notwithstanding
                  anything to the contrary contained herein, our liability to
                  you for any damages arising from or related to this agreement,
                  will at all times be limited to a maximum of fifty U.S.
                  dollars (u.s. $50). The existence of more than one claim will
                  not enlarge this limit. You agree that our suppliers will have
                  no liability of any kind arising from or relating to this
                  agreement.
                </p>

                <p>
                  Some jurisdictions do not allow the limitation or exclusion of
                  liability for incidental or consequential damages, so the
                  above limitation or exclusion may not apply to you.
                </p>

                <p>
                  <b>Term and Termination.</b> Subject to this Section, these
                  Terms will remain in full force and effect while you use the
                  Site. We may suspend or terminate your rights to use the Site
                  at any time for any reason at our sole discretion, including
                  for any use of the Site in violation of these Terms. Upon
                  termination of your rights under these Terms, your Account and
                  right to access and use the Site will terminate immediately.
                  You understand that any termination of your Account may
                  involve deletion of your User Content associated with your
                  Account from our live databases. Company will not have any
                  liability whatsoever to you for any termination of your rights
                  under these Terms. Even after your rights under these Terms
                  are terminated, the following provisions of these Terms will
                  remain in effect: Sections 2 through 2.5, Section 3 and
                  Sections 4 through 10.
                </p>

                <h3>Copyright Policy.</h3>
                <p>
                  Company respects the intellectual property of others and asks
                  that users of our Site do the same. In connection with our
                  Site, we have adopted and implemented a policy respecting
                  copyright law that provides for the removal of any infringing
                  materials and for the termination of users of our online Site
                  who are repeat infringers of intellectual property rights,
                  including copyrights. If you believe that one of our users is,
                  through the use of our Site, unlawfully infringing the
                  copyright(s) in a work, and wish to have the allegedly
                  infringing material removed, the following information in the
                  form of a written notification (pursuant to 17 U.S.C. §
                  512(c)) must be provided to our designated Copyright Agent:
                </p>

                <ul>
                  <li>your physical or electronic signature.</li>
                  <li>
                    identification of the copyrighted work(s) that you claim to
                    have been infringed.
                  </li>
                  <li>
                    identification of the material on our services that you
                    claim is infringing and that you request us to remove.
                  </li>
                  <li>
                    sufficient information to permit us to locate such material.
                  </li>
                  <li>your address, telephone number, and e-mail address</li>
                  <li>
                    a statement that you have a good faith belief that use of
                    the objectionable material is not authorized by the
                    copyright owner, its agent, or under the law and
                  </li>
                  <li>
                    a statement that the information in the notification is
                    accurate, and under penalty of perjury, that you are either
                    the owner of the copyright that has allegedly been infringed
                    or that you are authorized to act on behalf of the copyright
                    owner.
                  </li>
                </ul>

                <p>
                  Please note that, pursuant to 17 U.S.C. $ 512(f), any
                  misrepresentation of material fact in a written notification
                  automatically subjects the complaining party to liability for
                  any damages, costs and attorney's fees incurred by us in
                  connection with the written notification and allegation of
                  copyright infringement.
                </p>

                <h3>General </h3>
                <p>
                  These Terms are subject to occasional revision, and if we make
                  any substantial changes, we may notify you by sending you an
                  e-mail to the last e-mail address you provided to us and/or by
                  prominently posting notice of the changes on our Site. You are
                  responsible for providing us with your most current email
                  address. In the event that the last e-mail address that you
                  have provided us is not valid, our dispatch of the e-mail
                  containing such notice will nonetheless constitute effective
                  notice of the changes described in the notice. Any changes to
                  these Terms will be effective upon the earliest of thirty (30)
                  calendar days following our dispatch of an email notice to you
                  or thirty (30) calendar days following our posting of notice
                  of the changes on our Site. These changes will be effective
                  immediately for new users of our Site. Continued use of our
                  Site following notice of such changes shall indicate your
                  acknowledgement of such changes and agreement to be bound by
                  the terms and conditions of such changes. Dispute Resolution.
                  Please read this Arbitration Agreement carefully. It is part
                  of your contract with the Company and affects your rights. It
                  contains procedures for MANDATORY BINDING ARBITRATION AND A
                  CLASS ACTION WAIVER.
                </p>
                <p>
                  <b>Applicability of Arbitration Agreement.</b> All claims and
                  disputes in connection with the Terms or the use of any
                  product or service provided by the Company that cannot be
                  resolved informally or in small claims court shall be resolved
                  by binding arbitration on an individual basis under the terms
                  of this Arbitration Agreement. Unless otherwise agreed to, all
                  arbitration proceedings shall be held in English. This
                  Arbitration Agreement applies to you and the Company, and to
                  any subsidiaries, affiliates, agents, employees, predecessors
                  in interest, successors, and assigns, as well as all
                  authorized or unauthorized users or beneficiaries of services
                  or goods provided under the Terms.
                </p>
                <p>
                  <b>Notice Requirement and Informal Dispute Resolution.</b>{" "}
                  Before either party may seek arbitration, the party must first
                  send to the other party a written Notice of Dispute describing
                  the nature and basis of the claim or dispute, and the
                  requested relief. A Notice to the Company should be sent to:
                  52 maple st . After the Notice is received, you and the
                  Company may attempt to resolve the claim or dispute
                  informally. If you and the Company do not resolve the claim or
                  dispute within thirty (30) days after the Notice is received,
                  either party may begin an arbitration proceeding. The amount
                  of any settlement offer made by any party may not be disclosed
                  to the arbitrator until after the arbitrator has determined
                  the amount of the award to which either party is entitled.
                </p>

                <p>
                  <b>Arbitration Rules.</b> Arbitration shall be initiated
                  through the American Arbitration Association, an established
                  alternative dispute resolution provider that offers
                  arbitration as set forth in this section. If AAA is not
                  available to arbitrate, the parties shall agree to select an
                  alternative ADR Provider. The rules of the ADR Provider shall
                  govern all aspects of the arbitration except to the extent
                  such rules are in conflict with the Terms. The AAA Consumer
                  Arbitration Rules governing the arbitration are available
                  online at adr.org or by calling the AAA at 1-800-778-7879. The
                  arbitration shall be conducted by a single, neutral
                  arbitrator. Any claims or disputes where the total amount of
                  the award sought is less than Ten Thousand U.S. Dollars (US
                  $10,000.00) may be resolved through binding
                  non-appearance-based arbitration, at the option of the party
                  seeking relief. For claims or disputes where the total amount
                  of the award sought is Ten Thousand U.S. Dollars (US
                  $10,000.00) or more, the right to a hearing will be determined
                  by the Arbitration Rules. Any hearing will be held in a
                  location within 100 miles of your residence, unless you reside
                  outside of the United States, and unless the parties agree
                  otherwise. If you reside outside of the U.S., the arbitrator
                  shall give the parties reasonable notice of the date, time and
                  place of any oral hearings. Any judgment on the award rendered
                  by the arbitrator may be entered in any court of competent
                  jurisdiction. If the arbitrator grants you an award that is
                  greater than the last settlement offer that the Company made
                  to you prior to the initiation of arbitration, the Company
                  will pay you the greater of the award or $2,500.00. Each party
                  shall bear its own costs and disbursements arising out of the
                  arbitration and shall pay an equal share of the fees and costs
                  of the ADR Provider.
                </p>

                <p>
                  <b>Additional Rules for Non-Appearance Based Arbitration.</b>{" "}
                  If non-appearance based arbitration is elected, the
                  arbitration shall be conducted by telephone, online and/or
                  based solely on written submissions; the specific manner shall
                  be chosen by the party initiating the arbitration. The
                  arbitration shall not involve any personal appearance by the
                  parties or witnesses unless otherwise agreed by the parties.
                </p>
                <p>
                  <b>Time Limits.</b> If you or the Company pursues arbitration,
                  the arbitration action must be initiated and/or demanded
                  within the statute of limitations and within any deadline
                  imposed under the AAA Rules for the pertinent claim.
                </p>
                <p>
                  <b>Authority of Arbitrator.</b> If arbitration is initiated,
                  the arbitrator will decide the rights and liabilities of you
                  and the Company, and the dispute will not be consolidated with
                  any other matters or joined with any other cases or parties.
                  The arbitrator shall have the authority to grant motions
                  dispositive of all or part of any claim. The arbitrator shall
                  have the authority to award monetary damages, and to grant any
                  non-monetary remedy or relief available to an individual under
                  applicable law, the AAA Rules, and the Terms. The arbitrator
                  shall issue a written award and statement of decision
                  describing the essential findings and conclusions on which the
                  award is based. The arbitrator has the same authority to award
                  relief on an individual basis that a judge in a court of law
                  would have. The award of the arbitrator is final and binding
                  upon you and the Company.
                </p>
                <p>
                  <b>Waiver of Jury Trial.</b> THE PARTIES HEREBY WAIVE THEIR
                  CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
                  TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all
                  claims and disputes shall be resolved by arbitration under
                  this Arbitration Agreement. Arbitration procedures are
                  typically more limited, more efficient and less expensive than
                  rules applicable in a court and are subject to very limited
                  review by a court. In the event any litigation should arise
                  between you and the Company in any state or federal court in a
                  suit to vacate or enforce an arbitration award or otherwise,
                  YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead
                  electing that the dispute be resolved by a judge.
                </p>

                <p>
                  <b>Waiver of Class or Consolidated Actions.</b> All claims and
                  disputes within the scope of this arbitration agreement must
                  be arbitrated or litigated on an individual basis and not on a
                  class basis, and claims of more than one customer or user
                  cannot be arbitrated or litigated jointly or consolidated with
                  those of any other customer or user.
                </p>
                <p>
                  <b>Confidentiality.</b> All aspects of the arbitration
                  proceeding shall be strictly confidential. The parties agree
                  to maintain confidentiality unless otherwise required by law.
                  This paragraph shall not prevent a party from submitting to a
                  court of law any information necessary to enforce this
                  Agreement, to enforce an arbitration award, or to seek
                  injunctive or equitable relief.
                </p>
                <p>
                  <b>Severability.</b> If any part or parts of this Arbitration
                  Agreement are found under the law to be invalid or
                  unenforceable by a court of competent jurisdiction, then such
                  specific part or parts shall be of no force and effect and
                  shall be severed and the remainder of the Agreement shall
                  continue in full force and effect.
                </p>
                <p>
                  <b>Right to Waive.</b> Any or all of the rights and
                  limitations set forth in this Arbitration Agreement may be
                  waived by the party against whom the claim is asserted. Such
                  waiver shall not waive or affect any other portion of this
                  Arbitration Agreement.
                </p>
                <p>
                  <b>Survival of Agreement.</b> This Arbitration Agreement will
                  survive the termination of your relationship with the Company.
                </p>
                <p>
                  <b>Small Claims Court.</b> Nonetheless the foregoing, either
                  you or the Company may bring an individual action in small
                  claims court.
                </p>
                <p>
                  <b>Emergency Equitable Relief.</b> Anyhow the foregoing,
                  either party may seek emergency equitable relief before a
                  state or federal court in order to maintain the status quo
                  pending arbitration. A request for interim measures shall not
                  be deemed a waiver of any other rights or obligations under
                  this Arbitration Agreement.
                </p>
                <p>
                  <b>Claims Not Subject to Arbitration.</b> Notwithstanding the
                  foregoing, claims of defamation, violation of the Computer
                  Fraud and Abuse Act, and infringement or misappropriation of
                  the other party's patent, copyright, trademark or trade
                  secrets shall not be subject to this Arbitration Agreement.
                </p>
                <p>
                  In any circumstances where the foregoing Arbitration Agreement
                  permits the parties to litigate in court, the parties hereby
                  agree to submit to the personal jurisdiction of the courts
                  located within US County, California, for such purposes.
                </p>
                <p>
                  The Site may be subject to U.S. export control laws and may be
                  subject to export or import regulations in other countries.
                  You agree not to export, re-export, or transfer, directly or
                  indirectly, any U.S. technical data acquired from Company, or
                  any products utilizing such data, in violation of the United
                  States export laws or regulations.
                </p>
                <p>
                  Company is located at the address in Section 10.8. If you are
                  a California resident, you may report complaints to the
                  Complaint Assistance Unit of the Division of Consumer Product
                  of the California Department of Consumer Affairs by contacting
                  them in writing at 400 R Street, Sacramento, CA 95814, or by
                  telephone at (800) 952-5210.
                </p>

                <p>
                  <b>Electronic Communications.</b> The communications between
                  you and Company use electronic means, whether you use the Site
                  or send us emails, or whether Company posts notices on the
                  Site or communicates with you via email. For contractual
                  purposes, you (a) consent to receive communications from
                  Company in an electronic form; and (b) agree that all terms
                  and conditions, agreements, notices, disclosures, and other
                  communications that Company provides to you electronically
                  satisfy any legal obligation that such communications would
                  satisfy if it were be in a hard copy writing.
                </p>
                <p>
                  <b>Entire Terms.</b> These Terms constitute the entire
                  agreement between you and us regarding the use of the Site.
                  Our failure to exercise or enforce any right or provision of
                  these Terms shall not operate as a waiver of such right or
                  provision. The section titles in these Terms are for
                  convenience only and have no legal or contractual effect. The
                  word "including" means "including without limitation". If any
                  provision of these Terms is held to be invalid or
                  unenforceable, the other provisions of these Terms will be
                  unimpaired and the invalid or unenforceable provision will be
                  deemed modified so that it is valid and enforceable to the
                  maximum extent permitted by law. Your relationship to Company
                  is that of an independent contractor, and neither party is an
                  agent or partner of the other. These Terms, and your rights
                  and obligations herein, may not be assigned, subcontracted,
                  delegated, or otherwise transferred by you without Company's
                  prior written consent, and any attempted assignment,
                  subcontract, delegation, or transfer in violation of the
                  foregoing will be null and void. Companies may freely assign
                  these Terms. The terms and conditions set forth in these Terms
                  shall be binding upon assignees.
                </p>
                <p>
                  <b>Your Privacy.</b> Please read our Privacy Policy.
                </p>
                <p>
                  <b>Copyright/Trademark Information.</b> Copyright ©. All
                  rights reserved. All trademarks, logos, testimonials, icons
                  and service marks displayed on the Site are not our property
                  but the property of other third-parties. You are not permitted
                  to use these Marks without our prior written consent or the
                  consent of such third parties which may own the Marks.
                </p>
                {/* <h3>Contact Information</h3>
                <p>
                  Email:{" "}
                  <a href="mailto:contact@archer.com">contact@archer.com</a> ,
                  <a href="mailto:pavan@workershive.agency ">
                    pavan@workershive.agency{" "}
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {/* Privacy Policy  */}
        <div
          className="modal fade site-policy"
          id="privacy_policy"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-header">
                <h2 className="modal-title text-center" id="ModalLabel">
                  Introduction
                </h2>
              </div>
              <div className="modal-body">
                <p>
                  WorkersHive is the creator, developer, and operator of an
                  online software and mobile platform that facilitates the
                  connection between businesses (“Partners”) and workers
                  (“Professionals”) for one-time or recurring work
                  opportunities. When you utilize our platform, including our
                  websites, mobile applications, content, and services
                  (collectively referred to as our “Services”), you entrust us
                  with your information. Safeguarding your privacy is of utmost
                  importance to us. This policy, known as our “Privacy Policy,”
                  outlines how we gather, utilize, and share your personal data.
                </p>
                <p>
                  Please carefully review this Privacy Policy, which is
                  incorporated into our Terms of Use (“Terms”), accessible at
                  [link to WorkersHive's Terms of Use]. By accessing or using
                  our Services in any capacity, you agree to our Terms and
                  explicitly authorize us to collect, receive, use, retain, and
                  disclose/transmit your Information to third parties as
                  detailed in this Privacy Policy. Since accessing or using our
                  Services necessitates accepting our Terms, reach out to us at
                  privacy@workershive.com or at the address provided at the end
                  of this Privacy Policy if you have any queries.
                </p>

                <h3>What We Collect</h3>
                <p>
                  Within this Privacy Policy, “we,” “us,” “our,” and
                  “WorkersHive” pertain to [Company Name], and “you” and “your”
                  refer to you as a user of our Services, whether as a Partner,
                  Professional, Reference, or in another capacity. We refer to
                  the collective information you provide us as your
                  “Information,” encompassing your Personal Information,
                  Non-Personal Information, and Usage Data.
                </p>

                <p>
                  “Personal Information” (sometimes known as “Personally
                  Identifiable Information”) refers to data that personally
                  identifies you—either alone or combined with Usage Data and
                  Non-Personal Information. It includes, but is not limited to,
                  your name, email address, social security number,
                  mailing/physical address, mobile device number, telephone
                  number, government identification number(s), date of birth,
                  photograph, and other identifying information.
                </p>

                <p>
                  Personal Information may also encompass details you offer us
                  concerning a payment service (“Payment Service”) provided for
                  your convenience in connection with the Services. This may
                  involve maintaining your credit card or bank account
                  information (each a “Payment Account”) securely with us in
                  association with your Registered Account, as defined below. If
                  you possess an active business debit card account through
                  WorkersHive (a “WorkersHive Debit Account”) for
                  business-related expenses, we may collect information linked
                  to your WorkersHive Debit Account, including your transactions
                  and other activities related to its use. This information may
                  include, among other things, your IP address, MAC address,
                  device-specific information, device type, unique device
                  identifiers, advertising identifiers, location information,
                  and browsing activity and history (collectively, “Usage
                  Data”).
                </p>

                <p>
                  “Non-Personal Information” pertains to data that does not
                  personally or specifically identify you, such as aggregate or
                  non-specific Usage Data and information about devices,
                  operating systems, browsing activities, locations, usage
                  patterns, and other non-identifying analytics about our
                  Services’ users.
                </p>

                <h3>Information You Directly Provide to Us</h3>
                <p>
                  Partners and Professionals utilize our Services to match
                  available work opportunities with qualified workers. To
                  facilitate these connections, both parties must register and
                  maintain an active Services account (a “Registered Account”).
                  For instance:
                </p>

                <p>
                  If you post a shift as a Partner or create a Registered
                  Account, we may collect your name, email address, and other
                  Personal Information. Similarly, as a Professional, we may
                  collect your name, email address, home address, and other
                  Personal Information you submit during registration, profile
                  creation, or the shift-matching process (including your
                  resume, References’ names, and contact information, and
                  pertinent data for using the Services). Specifically, we
                  gather information about you (including Personal Information)
                  that you choose to provide to us in relation to your Services
                  use, including data submitted when you:
                </p>

                <p>
                  Create or update your Registered Account (which may include
                  Personal Information like name, email, social security number,
                  mobile device number, phone number, address, payment/banking
                  information, government identification numbers, birth date,
                  and photographs). Request specific services or features
                  through the Services (such as via our mobile applications or
                  websites).
                </p>

                <p>
                  <b>Contact WorkersHive,</b> including for support purposes.
                  Interact with other users (e.g., Professionals, References, or
                  Partners) through our Services. Engage in work shifts as a
                  Professional or post shifts as a Partner through our Services.
                  Participate in surveys sent by WorkersHive or on its behalf.
                  Enable features requiring WorkersHive’s access to your address
                  book or calendar. Information Generated Through Service Usage
                  This encompasses:
                </p>

                <p>
                  <b>Location Information:</b> Depending on the Services and
                  your mobile application settings or device permissions,
                  WorkersHive may collect your precise or approximate location
                  data using methods such as GPS, IP address, and WiFi. We
                  collect location information when the mobile application is in
                  use (either in the foreground or background). However, you may
                  use the mobile application without enabling WorkersHive to
                  collect your location information, although this might limit
                  certain functionalities.
                </p>

                <p>
                  <b>Transaction Information:</b> We collect details and data
                  related to your Service use, including types of Services
                  requested/provided, engagement details (dates, times,
                  durations), fees, purchases made with a business debit card
                  (if using this service via WorkersHive), and other associated
                  transaction specifics.
                </p>

                <p>
                  <b>Usage and Preference Information:</b> We collect data on
                  how you interact with our Services, preferences, and settings
                  chosen. In certain cases, we gather Usage Data through
                  cookies, web beacons, pixel tags, and similar technologies to
                  enhance Services quality and usability.
                </p>

                <p>
                  <b>Device Information:</b> Information about the devices you
                  use to access our Services, alongside relevant Usage Data. Log
                  Information: When you interact with our Services, we gather
                  server logs, including Usage Data like device IP address,
                  access timestamps, viewed pages, application crashes, browser
                  type, and preceding third-party sites/services used.
                </p>

                <p>
                  <b>Calls and Text Messages:</b> WorkersHive enables
                  user-to-user communication through the Services, capturing
                  call/text details (date, time, content) for safety, security,
                  analytics, and support purposes. By using the Services, you
                  consent to such Communications and Alerts Service via
                  telephone, email, short message service, or text message.
                  Address Book and Calendar Information: If you grant
                  WorkersHive access to your device’s address book/contacts or
                  calendar, we may collect related contact data to facilitate
                  social interactions and for purposes specified in this Privacy
                  Policy or upon consent/collection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
