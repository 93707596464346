/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";

export class Header extends Component {
  render() {
    return (
      <>
        <nav
          className="navbar navbar-default navbar-default-extra"
          data-spy="affix"
          data-offset-top="450"
          // style={{ backgroundColor: "#c9cacb66" }}
        >
          <div className="container">
            <div className="navbar-header">
              <a className="navbar-brand" href="/">
                <div className="logo">
                  <span className="workersHive_1 workers_2">Workers</span>
                  <span className="workersHive_1 hive">Hive</span>
                </div>
                <div className="logo-nav">
                  <span className="workersHive_2 workers_2">Workers</span>
                  <span className="workersHive_2 hive">Hive</span>
                </div>
              </a>
            </div>
            {/* <ul className="nav navbar-nav navbar-right">
              <li className="hidden-xs">
                <a href="https://app.workershive.agency/login" className="workers_2">Sign In</a>
              </li>
              <li className="hidden-xs">
                <a
                  href="https://app.workershive.agency/register"
                  className="btn roundBtn workers_2"
                >
                  Register
                </a>
              </li>
              <li className="hidden-md hidden-lg">
                <a id="toggle">
                  <i className="fa fa-bars fa-2x"></i>
                  <i className="fa fa-times fa-2x"></i>
                </a>
              </li>
            </ul> */}
          </div>
        </nav>

        {/* <div
          className="mobile-nav-overlay hidden-md hidden-lg"
          id="mobile-nav-overlay"
        >
          <nav
            className="mobile-nav"
          >
            <ul>
              <li className="hidden-sm">
                <a href="https://app.workershive.agency/login">Sign In</a>
              </li>
              <li className="hidden-sm">
                <a href="https://app.workershive.agency/register">
                  Register
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </>
    );
  }
}

export default Header;
