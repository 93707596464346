/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";
import Header from "./HeaderOther";
import Footer from "./Footer";

export class Warehousing extends Component {
  render() {
    return (
      <main className="main" id="main">
        <header className="hero-section-other">
          <Header />
        </header>

        <section
          className="how-it-works section-spacing"
          style={{ backgroundColor: "#fff", marginTop: "4%" }}
        >
          <div className="container">
            {/* <header className="section-header text-center">
              <br />
              <h1 className="">
                Connect with a vast network of <span className="title2">food service workers</span> in your Area
              </h1>
              <h3 style={{ fontSize: "16px" }} className="titlefont">
                WorkersHive seamlessly matches businesses with pre-screened Front of House and Back of House food service workers who are actively seeking flexible, seasonal, and temp-to-hire positions.
              </h3>
            </header> */}
            <div className="row">
              <div className="col-md-6">
                <h1 className="titlefont">
                  Network with <span className="title2">warehouse workers</span>{" "}
                  in your local vicinity.
                </h1>
                <h3
                  style={{
                    fontSize: "16px",
                    textAlign: "left",
                    lineHeight: "1.5",
                  }}
                  className="titlefont"
                >
                  WorkersHive facilitates efficient connections between
                  businesses and a diverse pool of pre-vetted warehouse and
                  manufacturing professionals actively seeking flexible,
                  seasonal, and temp-to-permanent employment opportunities.
                </h3>

                {/* <span className="input-group-btn">
                  <a
                    href="https://app.workershive.agency/register"
                    className="btn roundBtn"
                  >
                    Find Workers
                  </a>
                </span> */}
              </div>
              <div className="col-md-6">
                <aside className="text-center" style={{ marginTop: "36px" }}>
                  <img
                    src="assets/new_img/warehouse/warehouse.jpg"
                    alt="feature-1"
                  />
                </aside>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-spacing"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <header className="section-header">
                  <h2 className="titlefont">
                    Trusted by{" "}
                    <span className="title2"> small and large businesses</span>
                  </h2>
                </header>

                <div class="image-container-trusted">
                  <img
                    src="assets/new_img/trusted/1.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/2.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/3.png"
                    alt="contact-us"
                    className="image-trusted"
                  />

                  <img
                    src="assets/new_img/trusted/4.png"
                    alt="contact-us"
                    className="image-trusted"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="trusted-clients section-spacing">
          <div className="container">
            <div className="row">
              <h2 className="titlefont foodTrustedTitle"> Trusted by restaurants, caterers, and dining facilities nationwide </h2>
              <div className="col-md-11 center-block">
                <ul className="row product-statistics text-center">
                  <li className="col-sm-3">
                    {" "}
                    <i className="fa fa-users"></i>
                    <h3>
                      <span className="counter-1">50</span>k+
                      <span className="statistics-achieved">Members</span>
                    </h3>
                  </li>
                  <li className="col-sm-3">
                    {" "}
                    <i className="fa fa-cloud-download"></i>
                    <h3>
                      <span className="counter-2">70</span>k+
                      <span className="statistics-achieved">Downloads</span>
                    </h3>
                  </li>
                  <li className="col-sm-3">
                    {" "}
                    <i className="fa fa-facebook-official"></i>
                    <h3>
                      <span className="counter-3">90</span>k+
                      <span className="statistics-achieved">Facebook</span>
                    </h3>
                  </li>
                  <li className="col-sm-3">
                    {" "}
                    <i className="fa fa-twitter"></i>
                    <h3>
                      <span className="counter-4">60</span>k+
                      <span className="statistics-achieved">Twitter</span>
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section> */}

        <section
          className="features text-center section-spacing foodBeverage"
          id="features"
          style={{ backgroundColor: "#f3f3f3" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row-margin">
                  <header className="section-header">
                    <h1 className="titlefont">
                      From general labor to forklift drivers,{" "}
                      <span className="title2">we've got you.</span>
                    </h1>
                    {/* <h3 className="titlefont">
                      <a href="https://app.workershive.agency/register">
                        create an account
                      </a>{" "}
                      to see the full list in your area.
                    </h3> */}
                  </header>
                  <div className="row">
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/2.png"
                        alt="feature-1"
                      />
                      <h4>Custodial</h4>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/3.png"
                        alt="feature-2"
                      />
                      <h4>Forklift Driver</h4>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/1.png"
                        alt="feature-3"
                      />
                      <h4>General Labor</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/4.png"
                        alt="feature-1"
                      />
                      <h4>Warehouse Admin</h4>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/5.png"
                        alt="feature-2"
                      />
                      <h4>Warehouse Assoc. - Entry Level</h4>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img
                        src="assets/new_img/warehouse/6.png"
                        alt="feature-3"
                      />
                      <h4>Warehouse Assoc. - Intermediate</h4>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-4.svg" alt="feature-4" />
                      <h4>Google Maps</h4>
                      <p>
                        Nullam pellentesque nisl nec tincidunt viverra hendrerit
                        felis mi.{" "}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-5.svg" alt="feature-5" />
                      <h4>Twitter Feed</h4>
                      <p>
                        Fusce finibus condimentum purus. Sed sagittis erat sed
                        posuere cursus.{" "}
                      </p>
                    </div>
                    <div className="col-sm-4">
                      {" "}
                      <img src="assets/img/feature-6.svg" alt="feature-6" />
                      <h4>Font Awesome</h4>
                      <p>
                        Proin convallis sodales volutpat. Suspene varius mollis
                        risus arcu.{" "}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    );
  }
}

export default Warehousing;
