/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";
import Header from "./HeaderOther";
import Footer from "./Footer";

export class About_US extends Component {
  render() {
    return (
      <main className="main" id="main">
        <header className="hero-section-other">
          <Header />
        </header>

        <section
          className="how-it-works section-spacing"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="container">
            <header className="section-header text-center">
              <br />
              <h2 className="titlefont">
                The future of <span className="title2">hourly work</span> has
                officially arrived
              </h2>
              <h3 style={{ fontSize: "16px", lineHeight: "1.5" }} className="titlefont">
                At WorkersHive, our core mission is to foster economic
                opportunities for{" "}
                <span className="title2">
                  {" "}
                  businesses and hourly workers worldwide
                </span>
                . We firmly believe that everyone should have the chance to
                excel in their careers, and our commitment is to empower our
                users to achieve just that.
              </h3>
            </header>
            <div className="row">
              <div className="col-md-11 center-block">
                <div className="row">
                  <div className="col-md-6 col-md-push-6 text-center">
                    {" "}
                    <img
                      src="assets/new_img/about/about1.jpg"
                      alt="step-1"
                      style={{ width: "280px" }}
                    />
                  </div>
                  <div className="col-md-6 col-md-pull-6">
                    <div className="step-number">
                      <span>1</span>
                    </div>
                    <h4 className="titlefont">In Brief</h4>
                    <p>
                      In 2023, WorkersHive was founded with the mission of
                      simplifying the connection between businesses and hourly
                      workers, aiming to make the hiring process as effortless
                      as online shopping. Today, WorkersHive has evolved into an
                      enhanced marketplace for hourly workers, providing a
                      superior solution in the industry.
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 text-center">
                    {" "}
                    <img
                      src="assets/new_img/about/about2.jpg"
                      alt="step-2"
                      style={{ width: "280px" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="step-number">
                      <span>2</span>
                    </div>
                    <h4 className="titlefont">What Makes Us Unique</h4>
                    <p>
                      <ul>
                        <li>
                          Placement: Ensuring the perfect match between job
                          roles and workers.
                        </li>
                        <li>
                          Reliability: Clients have the opportunity to evaluate
                          each professional's performance and productivity to
                          ensure a consistent standard of quality work output.
                        </li>
                        <li>
                          Volume: We possess a vast network of skilled
                          professionals readily accessible to deliver their
                          services with proficiency and dedication
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-md-push-6 text-center">
                    {" "}
                    <img
                      src="assets/new_img/about/about3.jpg"
                      alt="step-3"
                      style={{ width: "280px" }}
                    />
                  </div>
                  <div className="col-md-6 col-md-pull-6">
                    <div className="step-number">
                      <span>3</span>
                    </div>
                    <h4 className="titlefont">Quality Assurance</h4>
                    <p>
                      <ul>
                        <li>
                          Background Checks: Ensures trustworthy workers.
                        </li>
                        <li>
                          Work History & Certifications: Predicts reliability
                          and skill level.
                        </li>
                        <li>
                          On-site 'Captains': Manages large groups and
                          resolves conflicts.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-6 text-center">
                    {" "}
                    <img src="assets/img/step-4.svg" alt="step-4" />
                  </div>
                  <div className="col-md-6">
                    <div className="step-number">
                      <span>4</span>
                    </div>
                    <h4>Be part of our Mission</h4>
                    <p>
                    Whether you're a business seeking top talent or an individual looking for exciting opportunities, WorkersHive is your ideal platform. Together, we can reshape the future of hourly work, fostering a world where everyone can thrive.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    );
  }
}

export default About_US;
