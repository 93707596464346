/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import { Link   } from "react-router-dom";
import Header from "./HeaderOther";
import Footer from "./Footer";

export class Businesses extends Component {
  render() {
    return (
      <main className="main" id="main">
        <header className="hero-section-other">
          <Header />
        </header>

        <section
          className="how-it-works section-spacing"
          style={{ backgroundColor: "#fff", marginTop: "4%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1 className="titlefont">
                  Elevate your{" "}
                  <span className="title2"> workforce with flexibility.</span>
                </h1>
                <h3
                  style={{ fontSize: "16px", textAlign: "left" }}
                  className="titlefont"
                >
                  workerhive's adaptable approach attracts top-tier
                  professionals who are motivated to maintain punctuality and
                  deliver exceptional performance, contrasting with the
                  challenges of low caliber and inconsistent reliability often
                  associated with conventional temporary staffing solutions.
                </h3>

                {/* <span className="input-group-btn">
                  <a
                    href="https://app.workershive.agency/register"
                    className="btn roundBtn"
                  >
                    GET STARTED
                  </a>
                </span> */}
              </div>
              <div className="col-md-6">
                <aside className="text-center" style={{ marginTop: "36px" }}>
                  <img
                    src="assets/new_img/for-business/1.jpg"
                    alt="feature-1"
                  />
                </aside>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    );
  }
}

export default Businesses;
